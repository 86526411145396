import React from 'react';
import '../css/Buttons.css';
import { NavLink, useNavigate } from 'react-router-dom';

const ButtonLarge = ({ 
  label, 
  icon: Icon, 
  onClick, 
  to, 
  style, 
  className, 
  setIsButtonHovered, 
  disabled = false // Added disabled prop
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (disabled) return; // Prevent click when disabled
    if (to) {
      navigate(to);
    } else if (onClick) {
      onClick();
    }
  };

  return to ? (
    <NavLink
      to={to}
      className={({ isActive }) => `button-large ${className} ${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`} // Add disabled class
      style={style}
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
    >
      {Icon && <Icon className="button-large-icon" />}
      {label}
    </NavLink>
  ) : (
    <button
      className={`button-large hover-button ${className} ${disabled ? 'disabled' : ''}`} // Add disabled class
      onClick={handleClick}
      style={style}
      disabled={disabled} // Add disabled attribute to button element
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
    >
      {Icon && <Icon className="button-large-icon" />}
      {label}
    </button>
  );
};

export default ButtonLarge;
