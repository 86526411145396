import React from 'react';
import '../css/About.css';
import {ReactComponent as LogoExtended} from '../assets/logo-extended.svg';
import ExperienceBlock from '../components/ExperienceBlock';

 

const About = () => {
  return (
    <div className='about'>

      <LogoExtended className='logo-about'  />

      <div className='work-section'>

        <div className='work-title'>SKILLS</div>

        <div className='work-experiences'>
          
          <ExperienceBlock
            company="Soft skills"
            description="I’m really proactive and data-driven, I have a systemic view of design while being creative and communicating well with developers. I’m really curious, I work well with people and I’m always in pursuit of self-improvement."

          />

          <ExperienceBlock
            company="Tools"
            description="Figma, Sketch, HTML, CSS, React, Javascript (basic), Zeplin, After Effects, Photoshop, Illustrator, Indesign, Premiere, Maya, Blender."

          />

          <ExperienceBlock
            company="Languages"
            description={
              <>
                <strong>Portuguese</strong> (native); <strong>English</strong> (fluent); <strong>Spanish</strong> (fluent); <strong>Dutch</strong> (conversational); <strong>German</strong> (basic).
              </>
            }
          />

          

        </div>

      </div>

      <div className='work-section'>

        <div className='work-title'>WORK</div>

        <div className='work-experiences'>
          
          <ExperienceBlock
            dates="June 2023 - January 2024"
            title="Product designer"
            company="Wonderkind"
            description="In my role supporting two development teams, I led the establishment of a streamlined product design workflow and the enhancement of the company's agile methodology. Working in close collaboration to business analysts and product owner, my responsibilities spanned the entire feature development lifecycle: from the discovery phase and ideation, requirement gathering, through prototyping and user testing, to seamless handover to developers. Additionally, I played a pivotal role in structuring the company's design system, facilitating rapid prototyping to validate concepts efficiently."
          />

          <ExperienceBlock
            dates="September 2021 - June 2023 (overlap)"
            title="Sr. Product designer"
            company="Stryk – by bUX"
            description="As the sole product designer for the app, I led its design vision and product direction with a systemic approach. My contributions included designing features and flows, establishing a semantic color system, and overseeing the hiring of product designers. I also served as a key design team mentor, fostering collaboration and ensuring the delivery of high-quality design work."
          />

          <ExperienceBlock
            dates="January 2021 - December 2021 (overlap)"
            title="Product designer"
            company="BUX Zero - Onboarding"
            description="Focused on enhancing BUX Zero’s onboarding experience across mobile and web, I ran usability tests, analyzed user data, and implemented A/B tests to optimize performance. My work introduced animations and sound, improved user flows, and resulted in functional prototypes, all developed through an iterative design process."
          />

          <ExperienceBlock
            dates="June 2016 - January 2021"
            title="Sr. Creative designer"
            company="BUX"
            description="I founded the creative growth of the digital marketing team, structuring and maturing its processes. Alongside other designers, I helped establish BUX’s visual identity and that of its apps. My work included hiring and onboarding three creative designers, mentoring them to ensure team alignment and productivity."
          />

          <ExperienceBlock
            dates="September 2021 - June 2023 (overlap)"
            title="Web designer"
            company="Estacio de Sa University"
            description="I led the design and development of printed and digital books for graduation courses, blending graphic and didactic design to enhance learning outcomes. Additionally, I created learning objects tailored to the university’s online courses, ensuring clarity and accessibility in educational materials."
          />

        </div>

      </div>

      <div className='work-section'>

        <div className='work-title'>EDUCATION</div>

        <div className='work-experiences'>
          
          <ExperienceBlock
            dates="2006 - 2011"
            title="Graphic design"
            company="Universidade Federal do Rio de Janeiro"
            description=""
          />

        </div>

      </div>
      
    </div>
  );
};

export default About;