import React, { useEffect, useState, useRef } from 'react';
import '../css/Project.css';

const VideoSlide = ({ 
  videoSlideTitle, 
  videoSlideImage, 
  videoSlideVideo 
}) => {
  const [isInView, setIsInView] = useState(false);
  const [delayCompleted, setDelayCompleted] = useState(false); // Tracks if delay has completed
  const videoGroupRef = useRef(null);
  const videoRef = useRef(null); // Ref for video element

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.2 }
    );

    if (videoGroupRef.current) {
      observer.observe(videoGroupRef.current);
    }

    return () => {
      if (videoGroupRef.current) {
        observer.unobserve(videoGroupRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let delayTimeout;
    if (isInView && !delayCompleted) {
      // Start the delay timer when the element first comes into view
      delayTimeout = setTimeout(() => {
        setDelayCompleted(true); // Mark delay as completed after 3 seconds
      }, 2000);
    }
    return () => clearTimeout(delayTimeout); // Clear the timeout if the component unmounts
  }, [isInView, delayCompleted]);

  useEffect(() => {
    if (isInView && delayCompleted) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [isInView, delayCompleted]);

  return (
    <div className="image-section">
      <div className="video-slide-title">{videoSlideTitle}</div>
      <div 
        className={`video-slide-group ${isInView ? 'in-view' : ''}`}
        ref={videoGroupRef}
      >
        <div className="video-slide-image">
          <img src={videoSlideImage} alt={videoSlideTitle} />
        </div>
        <div className="video-slide-video">
          <video
            ref={videoRef}
            muted
            controls
            loop
          >
            <source src={videoSlideVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoSlide;
