import React, { useEffect, useState, useRef } from 'react';
import '../css/Project.css';

const TextSlide = ({ slideTitle, slideText, style }) => {
  const [isInView, setIsInView] = useState(false);
  const slideRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting); // Toggle animation on intersection
      },
      { threshold: 0.2 } // Adjust threshold for triggering animation
    );

    if (slideRef.current) {
      observer.observe(slideRef.current);
    }

    return () => {
      if (slideRef.current) {
        observer.unobserve(slideRef.current);
      }
    };
  }, []);

  return (
    <div className="text-slide-section">
      <div className="slide-title">{slideTitle}</div>
      <div 
        className={`slide-text ${isInView ? 'in-view' : ''}`} 
        ref={slideRef}
      >
        {Array.isArray(slideText)
          ? slideText.map((line, index) => (
              <p key={index} style={style}>
                {line}
              </p>
            ))
          : <p style={style}>{slideText}</p>}
      </div>
    </div>
  );
};

export default TextSlide;
