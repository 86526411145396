import React, { useState } from 'react';
import '../css/Buttons.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

const IndexListItem = ({
  label,
  icon: Icon,
  onClick, // Accept onClick prop for scrolling
  to,
  style,
  className = '',
  itemNumber,
}) => {
  const navigate = useNavigate();

  const [isItemHovered, setIsItemHovered] = useState(false);

  // Handle click action for both navigation and scrolling
  const handleClick = () => {
    if (to) {
      navigate(to); // For routing-based navigation
    } else if (onClick) {
      onClick(); // Trigger the onClick function (e.g., scroll to section)
    }
  };

  return to ? (
    <NavLink
      to={to}
      className={({ isActive }) => `index-list-item ${className} ${isActive ? 'active' : ''}`}
      style={style}
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
    >
      {itemNumber}
      {label}
    </NavLink>
  ) : (
    <button
      className={`index-list-item hover-list-item ${className}`}
      onClick={handleClick} // Use handleClick here
      style={style}
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
    >
      <div
        className="index-list-item-content"
        style={{
          borderBottom: `${isItemHovered ? '2px solid #3C008A' : '2px solid #201E1D'}`,
        }}
      >
        <div
          className="index-list-item-number"
          style={{
            backgroundColor: `${isItemHovered ? '#3C008A' : '#E5D8D1'}`,
            color: `${isItemHovered ? '#E5D8D1' : '#201E1D'}`,
          }}
        >
          {itemNumber}
        </div>

        <div
          className="index-list-item-label"
          style={{
            color: `${isItemHovered ? '#3C008A' : '#201E1D'}`,
          }}
        >
          {label}
        </div>

        <div
          className="index-list-item-icon"
          style={{
            color: `${isItemHovered ? '#3C008A' : '#99908B'}`,
          }}
        >
          <FaChevronRight />
        </div>
      </div>
    </button>
  );
};

export default IndexListItem;
