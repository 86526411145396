import React, { useEffect, useState, useRef } from 'react';
import '../css/Project.css';
import Lottie from "lottie-react";

const ImageSlide = ({
  imageSlideTitle,
  imageSlideImage,
  imageSlideCaption,
  style,
  animationData,
  showTitle = true,
  showImage = true,
  showCaption = true,
  showAnimation = false,
}) => {
  const [isInView, setIsInView] = useState(false);
  const imageGroupRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.2 }
    );

    if (imageGroupRef.current) {
      observer.observe(imageGroupRef.current);
    }

    return () => {
      if (imageGroupRef.current) {
        observer.unobserve(imageGroupRef.current);
      }
    };
  }, []);

  return (
    <div className="image-section">
      {showTitle && <div className="image-slide-title">{imageSlideTitle}</div>}

      <div
        className={`image-slide-image-group ${isInView ? 'in-view' : ''}`}
        ref={imageGroupRef}
      >
        {showImage && (
          <div className="image-slide-image" style={style}>
            <img src={imageSlideImage} alt={imageSlideTitle} />
          </div>
        )}

        {showCaption && (
          <div className="image-slide-caption">{imageSlideCaption}</div>
        )}

        {showAnimation && (
          <div className="image-slide-animation">
            <Lottie animationData={animationData} style={style} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSlide;
