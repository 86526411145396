import React, { useState, useEffect } from 'react';
import '../css/ProjectPage.css';
import '../css/Buttons.css';

import IndexListItem from '../components/IndexListItem';
import SectionCover from '../components/SectionCover';
import TextSlide from '../components/TextSlide';
import VideoSlide from '../components/VideoSlide';
import ColumnTextSlide from '../components/ColumnTextSlide';

import FloatingNavigation from '../components/FloatingNavigation';

import ImageSlide from '../components/ImageSlide';

import buxBrandsImage from '../assets/bux-brands-through-time.webp';

import strykOldScreens from '../assets/stryk-old-screens.webp';

import strykDarkModeScreens from '../assets/stryk-dark-mode.webp';

import strykOldAppNewLogo from '../assets/stryk-old-app-new-logo.webp';

import strykDarkColorHierarchy from '../assets/stryk-color-hierarchy-dark.webp';

import strykColorWheel from '../assets/stryk-color-wheel.webp';

import strykColorWheelApp from '../assets/stryk-color-wheel-app.webp';

import strykColorOptions from '../assets/stryk-color-options.webp';

import strykSurveyDesign from '../assets/stryk-survey-design.webp';

import strykSurveyResultBlue from '../assets/stryk-survey-result-blue.webp';

import strykSurveyResultGreen from '../assets/stryk-survey-result-green.webp';

import strykSurveyResultPurple from '../assets/stryk-survey-result-purple.webp';

import strykColorPosition from '../assets/stryk-color-positioning.webp';

import strykAccentColor from '../assets/stryk-accent-color.webp';

import strykLightColorHierarchy from '../assets/stryk-color-hierarchy-light.webp';

import strykFinalDesigns from '../assets/stryk-final-designs.webp';

import strykLightCode from '../assets/stryk-light-code.webp';

import strykColorExceptions from '../assets/stryk-color-exceptions.webp';

import strykAppBreakdown from '../assets/stryk-app-breakdown.webp';

import strykTonalPalette from '../assets/stryk-tonal-palette.webp';

import strykSemantiPalette from '../assets/stryk-semantic-colors.webp';

import strykConcept from '../assets/stryk-concept.webp';

import strykComponentLibrary from '../assets/stryk-component-library.webp';

import strykPrototypeVideo from '../assets/stryk-prototype-video.mp4';

import strykPrototypeImage from '../assets/stryk-prototype-image.webp';

import strykAllScreens from '../assets/stryk-all-screens.webp';



const ProjectStryk = () => {

  

  const sections = [
    { id: 'project-background', label: 'Project background' },
    { id: 'rebranding', label: 'Rebranding the app' },
    { id: 'colour-system', label: 'Colour system' },
    { id: 'component-library', label: 'Component library' },
  ];

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [showFloatingButtons, setShowFloatingButtons] = useState(false);

  // Smooth scroll to a section
  const scrollToElement = (selector) => {
    const element = document.querySelector(selector);
    if (element) {
      const yOffset = 1; // Adjust this value if you have a fixed header or specific spacing
      const yPosition = element.offsetTop + yOffset;
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
    } else {
      console.warn(`Element not found for selector: ${selector}`);
    }
  };

  // Handle scroll and update visibility/index
  useEffect(() => {
    const handleScroll = () => {
      // Check if the first section comes into view (the top of it, not the bottom)
      const firstSection = document.getElementById('project-background');
      const firstSectionTop = firstSection ? firstSection.getBoundingClientRect().top : 0;

      // Show floating buttons when the first section is in view
      setShowFloatingButtons(firstSectionTop <= 0);

      // Check the current section's visibility
      const offsets = sections.map((section) => {
        const el = document.getElementById(section.id);
        return el ? el.getBoundingClientRect().top : Infinity;
      });

      const closestIndex = offsets.findIndex((offset) => offset >= -10); // Account for small margin
      if (closestIndex !== -1 && closestIndex !== currentSectionIndex) {
        setCurrentSectionIndex(closestIndex);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections, currentSectionIndex]);

  return (
    <div className="project-body">
      {/* Header */}
      <div className="project-header">
        <h2>Stryk</h2>
        <div className="project-header-image"></div>
      </div>

      {/* Index Section */}
      <section className="project-index">
        <div className="index-wrapper">
          {sections.map((section, index) => (
            <IndexListItem
              key={section.id}
              itemNumber={index + 1}
              label={section.label}
              onClick={() => {
                // When index item is clicked, scroll to the corresponding section
                scrollToElement(`#${section.id}`);
              }}
            />
          ))}
        </div>
      </section>

      {/* Sections */}

      {/* Section 01 */}
      <div id="project-background">
        
        <SectionCover sectionNumber="1" sectionTitle="Project background" />
        
        <ImageSlide 
          imageSlideImage={buxBrandsImage}
          imageSlideTitle="BUX family of apps through time"
          
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        
        />

        <ImageSlide 
          imageSlideImage={strykOldScreens}
          imageSlideTitle="State of Stryk (BUX X) in 2018"
          imageSlideCaption="Light mode"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />


        
        <ImageSlide 
          imageSlideImage={strykDarkModeScreens}
          imageSlideCaption="Dark mode"
          showTitle={false}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <TextSlide
          slideTitle="Goals"
          slideText={[
            'Separate the app from the BUX brand',
            'Mature the app\'s light mode',
            'Consolidate source of truth',
          ]}
        />

      </div>

      
      {/* Section 02 */}

      <div className='project-section' id="rebranding">

        <SectionCover sectionNumber="2" sectionTitle="Rebranding the app" />

        <TextSlide
          slideTitle="Approach"
          slideText={[
            'Consulting agency to define naming',
            'Visual identity designed in-house',
            'Minimal changes to the app',
          ]}
        />

        <ImageSlide 
          imageSlideImage={strykOldAppNewLogo}
          imageSlideTitle="New name and logo"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />
        
      </div>


      {/* Section 03 */}

      <div id="colour-system">
        <SectionCover sectionNumber="3" sectionTitle="Colour system" />
{/* Section 03        
        <TextSlide
          slideTitle="Discovery"
          slideText={[
            'Color roles in UI',
            'App analysis',
            'Rebuild color system for scalability',
          ]}
        />

        <ColumnTextSlide
          slideTitle="Discovery"
          slideHeader1="Title 1"
          slideHeader2="Title 2"
          slideHeader3="Title 3"
          slideText1="Test with users to choose main color"
          slideText2="Test with users to choose main color"
          slideText3="Test with users to choose main color"
        />
*/}

        <TextSlide
          slideTitle="Approach"
          slideText={[
            'MVP with dark mode\'s old color hierarchy',
            'Test with users to choose main color',
            'Rebuild color system for scalability',
          ]}
        />

        

        <ImageSlide 
          imageSlideImage={strykDarkColorHierarchy}
          imageSlideTitle="Reusing dark mode's color hierarchy for MVP"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideImage={strykColorWheel}
          imageSlideTitle="The main color would have to be far enough on the color wheel not to be confused with BUX"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideImage={strykColorWheelApp}
          imageSlideTitle="After creating several combinations we were ready to test with users our top 3 picks"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideImage={strykColorOptions}
          imageSlideCaption="Selected options for the user test"
          imageSlideTitle="After creating several combinations we were ready to test with users our top 3 picks"
          showTitle={false}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <TextSlide
          slideTitle="Test goals"
          slideText={[
            'Gather insights from users regarding the colors proposed',
            'Evaluate user\'s perceptions regarding the proposed changes',
            'Define a direction to move forward',
          ]}
        />

        <TextSlide
          slideTitle="Survey design"
          slideText={[
            'Clear language and non-conflicting terms',
            'Concise and structured survey with 5-point scale',
            'Open-ended field for comments'
          ]}
        />

        <ImageSlide 
          imageSlideImage={strykSurveyDesign}
          imageSlideCaption="Selected options for the user test"
          imageSlideTitle="Survey design"
          showTitle={false}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        

        <ImageSlide 
          imageSlideImage={strykSurveyResultBlue}
          imageSlideTitle="Survey results (n=300)"
          imageSlideCaption="The blue version performed well"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideImage={strykSurveyResultGreen}
          imageSlideCaption="The green version performed well"
          showTitle={false}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideImage={strykSurveyResultPurple}
          imageSlideCaption="The purple version didn't perform so well"
          showTitle={false}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <TextSlide
          slideTitle="Why blue?"
          slideText={[
            'Use a hue that was already in the app with lower lightness and higher saturation',
            'Cost savings in marketing that was already using mostly blue',
            'Transitional state while preparing for a bigger future change',
          ]}
        />

        <ImageSlide 
          imageSlideTitle="Primary color positioning"
          imageSlideImage={strykColorPosition}
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="Accent color positioning"
          imageSlideImage={strykAccentColor}
          imageSlideCaption="Accent color complementary to main background for good contrast"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="Reusing our old dark-mode framework"
          imageSlideImage={strykLightColorHierarchy}
          imageSlideCaption="It works but we're still left with very problematic colors"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="How the app looks with the updates"
          imageSlideImage={strykFinalDesigns}
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="We still had a big problem to deal with"
          imageSlideImage={strykLightCode}
          imageSlideCaption="Our code was still a mess and our colors meant nothing"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="Reviewing the code we found several exceptions like:"
          imageSlideImage={strykColorExceptions}
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="Breaking down the app to find meaning in colors"
          imageSlideImage={strykAppBreakdown}
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="Designing a global palette"
          imageSlideImage={strykTonalPalette}
          imageSlideCaption="The global palette contains all possible colors in the system"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="Designing a semantic palette"
          imageSlideImage={strykSemantiPalette}
          imageSlideCaption="The semantic palette borrows colors from the global palette"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="This allowed us to make future updates a lot faster"
          imageSlideImage={strykConcept}
          imageSlideCaption="Concept of a future light mode"
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={true}     // Caption is shown
          showAnimation={false}
        />

        


      </div>

      

      {/* Section 04 */}

      <div id="component-library">
        <SectionCover sectionNumber="4" sectionTitle="Component library" />

        

        <TextSlide
          slideTitle="Why?"
          slideText={[
            'Migration from Sketch to Figma',
            'Clean up the house - align library with devs',
            'Introduce prototyping and testing of features to the team',
          ]}
        />

        <ImageSlide 
          imageSlideTitle="35 interactive configurable and interactive components"
          imageSlideImage={strykComponentLibrary}
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <ImageSlide 
          imageSlideTitle="Over 200 screens migrated from Sketch to Figma"
          imageSlideImage={strykAllScreens}
          showTitle={true}       // Title is shown
          showImage={true}      // Image is hidden
          showCaption={false}     // Caption is shown
          showAnimation={false}
        />

        <VideoSlide 

          videoSlideTitle="Fast prototyping made testing features easier "
          videoSlideImage={strykPrototypeImage}
          videoSlideVideo={strykPrototypeVideo}

        />
        <TextSlide
          slideTitle="Goals achieved"
          slideText={[
            'Separated the app from BUX',
            'Matured the app\'s light mode',
            'Consolidated source of truth',
          ]}
          style={{borderColor: 'green'}}
        />

      </div>
      
      <div className="project-header">
        <h2>Thanks!</h2>
        <div className="project-header-image"></div>
      </div>
      



      {/* Floating Navigation */}
      {showFloatingButtons && (
        <FloatingNavigation
          currentSectionIndex={currentSectionIndex}
          totalSections={sections.length}
          onPrevious={() => {
            if (currentSectionIndex > 0) {
              scrollToElement(`#${sections[currentSectionIndex - 1]?.id}`);
            }
          }}
          onNext={() => {
            if (currentSectionIndex < sections.length - 1) {
              scrollToElement(`#${sections[currentSectionIndex + 1]?.id}`);
            }
          }}
          onHome={() => scrollToElement('.project-index')}
        />
      )}
    </div>
  );
};

export default ProjectStryk;
