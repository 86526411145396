import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Project from './components/Projects';
import './App.css';
import Work from './pages/Work'
import About from './pages/About'
import ProjectStryk from './pages/ProjectStryk'
import ScrollToTop from './components/Scroll-top'; // Import the component


const App = () => {
  return (

    <div className='wrapper'>
    <Router>
    <ScrollToTop />
      <Navbar />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Work />} />
          <Route path="/About" element={<About />} />
          <Route path="/ProjectStryk" element={<ProjectStryk />} />
        </Routes>
      </main>
    </Router>
    </div>


   
  );
};

export default App;