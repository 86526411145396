import React, { useState } from 'react';
import '../css/Project.css';
import strykCover from '../assets/stryk-cover.webp';
import buxCover from '../assets/bux-cover.webp';

import ButtonLarge from '../components/Button-large';
import '../css/Buttons.css';
import Project from '../components/Projects';
import ProjectStryk from './ProjectStryk'


const Work = ({ }) => {

  const [isProjectVisible, setIsProjectVisible] = useState(false);


  const handleButtonClick = () => {
    setIsProjectVisible(!isProjectVisible); // Toggle visibility
  };
  

  return (
    <div className="homepage">
      <header className="intro">
        <h1>👋 Hi there! I’m Paulo, I’m a designer and I like solving problems.</h1>
        <p>I also like baking 👨‍🍳, woodworking 🔨, drawing 🖊️, cats 😺 and fruits 🍌</p>
      </header>

      {/* Work Section */}
      <h2>
        
        Work

      </h2>

        <section className="project-section project-container">
        
          <Project 
            image={strykCover} 
            title='Stryk'
            description="How to keep a trading app consistent through time with a design system"
            buttonLabel="View Project"
            to="/ProjectStryk"
          />

          <Project 
            image={buxCover} 
            title='BUX Zero'
            description="Onboarding users in a new platform"
            buttonLabel="Coming soon"
            to=""
            disabled={true}
          />

          {isProjectVisible && (
            <div className="project-popup">
            </div>
          )}
        
      </section>

    </div>
  );
};

export default Work;
