import React, { useEffect, useRef } from 'react';
import '../css/Project.css';

const SectionCover = ({ sectionNumber, sectionTitle }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          sectionRef.current.classList.add('animate');
        } else {
          sectionRef.current.classList.remove('animate');
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the element is in view
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="section-cover">
      <div className="section-limit">
        <div className="section-number" ref={sectionRef}>
          {sectionNumber}
        </div>
        <div className="section-title">{sectionTitle}</div>
      </div>
    </div>
  );
};

export default SectionCover;
