import React, { useEffect, useState, useRef } from 'react';
import '../css/About.css';

const ExperienceBlock = ({ dates, title, company, description }) => {
  
  return (
    <div className='experience-block'>
      <div className='experience-company'>{company}</div>
      <div className='experience-title'>{title}</div>
      <div className='experience-dates'>{dates}</div>
      
      <div className='experience-description'>{description}</div>
    </div>
  );
};

export default ExperienceBlock;
